<template>
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
		<template #title>
			<a-row type="flex" align="middle">
				<a-col :span="24" :md="12">
					<h6>{{ $t('sshKey.list.title') }}</h6>			
				</a-col>
			</a-row>
		</template>
		<a-table v-if="!fetchListing" :columns="columns" :data-source="rows" :pagination="false">
			<template slot="public_key" slot-scope="row">
				<span class="mr-10">{{ splitPublicKey(row.public_key) }}</span>
				<a-tag color="#2db7f5" style=" cursor: pointer;" @click="showPublicKey(row.public_key)">{{$t('sshKey.list.btnShow')}}</a-tag>
				<a-tag color="#ff4d4f" style=" cursor: pointer;" @click="confirmDeleteKey(row)">
					<a-icon type="delete" theme="outlined" />
					{{$t('delete')}}
				</a-tag>
			</template>
		</a-table>
		<a-spin v-else tip="Loading..."></a-spin>
		<div class="table-upload-btn">
			<createPoup @reload="getData" />
		</div>
	</a-card>
</template>

<script>

	import sshKeys from "@/services/server/ssh_keys"
	import deleteKey from "@/services/server/delete_ssh_key"
	import createPoup from "./create"
	import { notification } from 'ant-design-vue'

	export default ({
		components: {
			createPoup
		},
		data() {
			return {
				fetchListing: true,
				rows: [],
				columns: [
					{
						title: this.$t('name'),
						dataIndex: 'name'
					},
					{
						title: 'Fingerprint',
						dataIndex: 'fingerprint'
					},
					{
						title: 'Public key',
						scopedSlots: { customRender: 'public_key' }
					}
				]
			}
		},
		created() {
			this.getData()
		},
		methods: {
			getData() {
				sshKeys()
				.then(res=> {
					this.fetchListing = false
					if (this._.isObject(res)) {
						this.rows = res
					}
				})
			},
			splitPublicKey(val) {
				val = val.split(' ')
				val[1] = '...'
				return val.join(' ')
			},
			showPublicKey(val) {
				this.$info({
					title: '',
					okText: this.$t('popup.confirm'),
					content: val,
					onOk() {},
				});
			},
			confirmDeleteKey(row) {
				this.$confirm({
					title: `${this.$t('sshKey.actionDelete.title')} [${row.name}]`,
					content: this.$t('sshKey.actionDelete.sure') +` [${row.name}]`,
					okText: this.$t('sshKey.actionDelete.btn'),
					okType: 'danger',
					cancelText: this.$t('popup.cancel'),
					onOk: async ()=> {
						let res = await deleteKey(row.name)
						if (res) {
							notification.success({
								message: this.$t('sshKey.actionDelete.successNotify')
							})
							this.getData()
						}
					}
				})
			}
		}
	})

</script>